import React, { useState, useEffect } from 'react';

import { isMyGamesApp, showAuthBlock } from '@vkplay/shared';

import AuthContext from 'contexts/auth';

const Authorised: React.FC = ({ children }) => {
    const [user, setUser] = useState(window.VKPAppConfig.profile || {});
    const [isUnauthorized, setUnauthorized] = useState(false);

    useEffect(() => {
        window.VKPAppConfig.profile = user || {};
    }, [user]);

    function showAuthPopup(url): void {
        const defaultUrl = `${window.location.origin}${window.location.pathname}`;

        if (isMyGamesApp) {
            showAuthBlock(typeof url === 'string' ? url : defaultUrl);

            return;
        }

        const location = typeof url === 'string' ? url : defaultUrl;

        if (!window.__GEM) {
            return;
        }

        window.__GEM.showLogin(location);
    }

    function signOut() {
        setUser({});
    }

    function checkAuth(onSuccess, onFailure, options): void {
        if (!user.user_id) {
            if (typeof onFailure === 'function') {
                onFailure();
            }

            showAuthPopup(options?.redirectUrl);

            return false;
        }

        if (typeof onSuccess === 'function') {
            onSuccess();
        }

        return true;
    }

    return (
        <AuthContext.Provider value={{
            user, isUnauthorized, setUser, showAuthPopup, checkAuth, signOut, setUnauthorized,
        }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default React.memo(Authorised);
