import 'configs/gmr-window-config';

import React from 'react';

import { Backend } from '@vkplay/shared';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { PageProvider } from 'contexts/page';

import Authorised from 'layouts/Authorised';
import AppVKP from 'src/AppVKP';
import getLang from 'utils/getLang';

import { isGamecenter, isSafari, isSTB } from './neurons';

import 'scss/fonts.scss';
import 'scss/skin/page.scss';
import 'scss/skin/temp.scss';

document.body.dataset.theme = 'dark';
document.body.dataset.project = 'vkp';

import(/* webpackChunkName: "sentry" */ './configs/sentry').then(({ default: initSentry }) => {
    initSentry();
});

window.isSafari = isSafari();
window.isSTB = isSTB();
window.isGamecenter = isGamecenter();

async function init() {
    let data;

    try {
        data = await Backend.api_get('/social/profile/v2/session');
    } catch (error) {
        data = {};
        console.error(error);
    }

    const userLocale = data.locale;
    const lang = getLang(userLocale);
    const { session, profile } = data;

    const GEMS = window.__GEMS || {};

    if (!session) {
        GEMS.no_session = true;
    }

    GEMS.auth = 1;
    GEMS.enable_analytics = true;
    GEMS.current_user = session || {};
    GEMS.lang = lang;
    GEMS.token = data.token;
    GEMS.country_id = data.country_id;
    GEMS.region_id = data.region_id;
    window.__GEMS = GEMS;

    window.VKPAppConfig.profile = profile;
    renderApp();
}

const isSubPage = !window.location.hostname.includes('media');
const basename = `${isSubPage ? '/media' : ''}${window.isGamecenter ? '/gamecenter' : '/'}`;

console.debug({ basename });

function renderApp() {
    ReactDOM.render(
        (
        // eslint-disable-next-line react/jsx-filename-extension
            <BrowserRouter basename={basename}>
                <PageProvider>
                    <Authorised>
                        <AppVKP />
                    </Authorised>
                </PageProvider>
            </BrowserRouter>
        ), document.getElementById('app-body'),
    );
}

init();
