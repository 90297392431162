(function initConfig(): void {
    if (window.location.hostname.includes('media.dev-vkplay.')) { // STAGE OLD
        window.VKPAppConfig = {
            desktopHost: 'media.vkplay.ru',
            imgHost: 'vkplay.ru',
            pathPrefix: '',
            restDomain: 'api.dev-vkplay.ru',
        };
    } else if (window.location.hostname.includes('store.dev-vkplay.')) { // STAGE NEW
        window.VKPAppConfig = {
            desktopHost: 'vkplay.ru/media',
            imgHost: 'vkplay.ru',
            pathPrefix: '',
            restDomain: 'api.dev-vkplay.ru',
        };
    } else if (window.location.hostname.endsWith('gm3.dev-vkplay.ru')) { // DEV
        const regex = /\/\/(.+?)(-media|-store)/;
        const match = regex.exec(window.location.href);
        const user = match?.[1];

        window.VKPAppConfig = {
            desktopHost: 'vkplay.ru/media',
            imgHost: 'vkplay.ru',
            pathPrefix: '',
            restDomain: `${user}-api.gm3.dev-vkplay.ru`,
        };
    } else { // PROD
        window.VKPAppConfig = {
            desktopHost: 'vkplay.ru/media',
            imgHost: 'vkplay.ru',
            pathPrefix: '',
            restDomain: 'api.vkplay.ru',
        };
    }
}());
