import React, { useMemo } from 'react';

import cn from 'classnames';
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';

import { plural } from 'aliases';

import Skeleton from './Skeleton';

import 'scss/skin/breadcrumbs.scss';

import type { FC } from 'react';

interface Props {
    alias?: string;
    majorType?: string;
    customCrumb?: string;
    name?: string;
    dark?: boolean;
}

const Breadcrumbs: FC<Props> = ({
    alias,
    majorType,
    customCrumb,
    name,
    dark,
}) => {
    const compoundAlias = useMemo(() => `${majorType ? `${majorType}/` : ''}${alias}`, [alias, majorType]);

    function getStructuredData() {
        const schema = {
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: [
                {
                    '@type': 'ListItem',
                    position: 1,
                    item: {
                        '@type': 'WebPage',
                        // eslint-disable-next-line max-len
                        '@id': `https://${window.VKPAppConfig.desktopHost}${window.VKPAppConfig.pathPrefix}/`,
                        name: `VK Play ${plural.ru.media}`,
                    },
                },
            ],
        };

        if (!alias) {
            return JSON.stringify(schema, null, 2);
        }

        if (Object.keys(plural).includes(compoundAlias)) {
            schema.itemListElement = [
                ...schema.itemListElement,
                {
                    '@type': 'ListItem',
                    position: 2,
                    item: {
                        '@type': 'WebPage',
                        // eslint-disable-next-line max-len
                        '@id': `https://${window.VKPAppConfig.desktopHost}${window.VKPAppConfig.pathPrefix}/${compoundAlias}/`,
                        name: plural.ru[compoundAlias],
                    },
                },
            ];

            if (name) {
                schema.itemListElement = [
                    ...schema.itemListElement,
                    {
                        '@type': 'ListItem',
                        position: 3,
                        item: {
                            '@type': 'WebPage',
                            '@id': document.location.href,
                            name,
                        },
                    },
                ];
            }
        }

        return JSON.stringify(schema, null, 2);
    }

    function crumble() {
        if (name) {
            return (
                <NavLink
                    className="breadcrumbs__link"
                    activeClassName="breadcrumbs__link_disabled"
                    exact
                    to={`${window.VKPAppConfig.pathPrefix}/${compoundAlias}/`}
                >
                    {plural.ru[compoundAlias]}
                </NavLink>
            );
        }

        return (
            <span className="breadcrumbs__link breadcrumbs__link_disabled">{plural.ru[compoundAlias]}</span>
        );
    }

    return (
        <div
            className={cn(
                'breadcrumbs',
                {
                    breadcrumbs_dark: dark,
                },
            )}
        >
            <Helmet encodeSpecialCharacters={false}>
                <script type="application/ld+json">{getStructuredData()}</script>
            </Helmet>
            <NavLink
                className="breadcrumbs__link"
                activeClassName="breadcrumbs__link_disabled"
                exact
                to={`${window.VKPAppConfig.pathPrefix}/`}
            >
                {plural.ru.media}
            </NavLink>
            <span className="breadcrumbs__dot" />
            {customCrumb && (
                <span className="breadcrumbs__link breadcrumbs__link_disabled">
                    {customCrumb}
                </span>
            )}
            {!customCrumb && (alias ? crumble() : <Skeleton />)}
        </div>
    );
};

export default Breadcrumbs;
