import React, { useContext, useState, useEffect } from 'react';

import { Helmet } from 'react-helmet';

import AuthorContext from 'contexts/author';
import GameContext from 'contexts/game';
import PrerenderContext from 'contexts/prerender';
import StoryContext from 'contexts/story';

import { singular } from 'aliases';
import photon from 'photon';
import initJsonLd from 'src/json-ld';
import filterSearchParams from 'src/utils/filterSearchParams';

import manifestVKPSrc from 'misc/manifest-vkp.webmanifest';
import searchPlugin from 'misc/search_plugin.xml';

const { desktopHost } = window.VKPAppConfig;

const theBrand = 'VK Play';

const icons = {
    57: 'https://vkplay.ru/hotbox/favicon/android-chrome-57x57.png',
    76: 'https://vkplay.ru/hotbox/favicon/android-chrome-76x76.png',
    128: 'https://vkplay.ru/hotbox/favicon/android-chrome-128x128.png',
    152: 'https://vkplay.ru/hotbox/favicon/android-chrome-152x152.png',
    167: 'https://vkplay.ru/hotbox/favicon/android-chrome-167x167.png',
    180: 'https://vkplay.ru/hotbox/favicon/android-chrome-180x180.png',
    192: 'https://vkplay.ru/hotbox/favicon/android-chrome-192x192.png',
    512: 'https://vkplay.ru/hotbox/favicon/android-chrome-512x512.png',
};

const theSvgIcon = 'https://vkplay.ru/hotbox/favicon/favicon.svg';
const theDefaultLogo = 'https://vkplay.ru/hotbox/images/vk_400x400.png';

const theManifest = manifestVKPSrc;

const imageProps = {
    width: 0,
    height: 460,
    factor: 2,
};

// скрипт для рендериллы, чтобы перенести мету выше стилей
window.prependMetaTags = (): void => {
    const head = document.head || document.getElementsByTagName('head')[0];
    // eslint-disable-next-line max-len
    const tagsToMove = head.querySelectorAll('[data-renderilla-prepend=true], [type="application/ld+json"]');

    if (tagsToMove.length) {
        head.prepend(...tagsToMove);
    }
};

export type AllowedQueryParameters = string[] | Record<string, string[]>[];

export interface HeadProps {
    title: string;
    description: string;
    image?: string;
    forcedImage?: string;
    imageWidth?: string;
    imageHeight?: string;
    published?: string;
    titlePostfix?: string;
    isStory?: boolean;
    alias?: string;
    canonical?: string;
    slug?: string;
    noBrand?: boolean;
    httpEquiv?: string;
    noIndex?: boolean;
    allowedQueryParameters?: AllowedQueryParameters;
}

const Head: React.FC<HeadProps> = ({
    title,
    description,
    image,
    forcedImage,
    imageWidth,
    imageHeight,
    published,
    titlePostfix,
    isStory,
    alias,
    canonical,
    slug,
    noBrand,
    httpEquiv,
    noIndex,
    allowedQueryParameters,
}) => {
    const { setPrerenderReady, isPrerenderReady } = useContext(PrerenderContext);
    const storyData = useContext(StoryContext);
    const authorData = useContext(AuthorContext);
    const gameData = useContext(GameContext);

    const [meta, setMeta] = useState(null);
    const [isMetaReady, setMetaReady] = useState(false);

    useEffect(() => {
        setMeta({
            alias: alias || 'news',
            title: title + titlePostfix,
            titleBranded: withBrand(title) + titlePostfix,
            description: withBrand(description),
            absoluteUrl: window.location.href,
            canonical:
                filterSearchParams(canonical, allowedQueryParameters) || filterSearchParams(
                    `https://${desktopHost}${window.location.pathname}${window.location.search}`,
                    allowedQueryParameters,
                ),
            published,
            ...getImageData(),
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [title, description, image, published, titlePostfix]);

    useEffect(() => {
        if (!isMetaReady || isPrerenderReady) {
            return;
        }

        const data = {
            ...storyData,
            author: authorData,
        };

        if (meta.alias === 'news') {
            initJsonLd.news(data);
            setPrerenderReady(true);
        } else if (meta.alias === 'video') {
            initJsonLd.video({
                ...data,
                descr: data.descr || data.name,
            });

            setPrerenderReady(true);
        } else if (meta.alias === 'review') {
            if (gameData) {
                initJsonLd.review(data, gameData);
                setPrerenderReady(true);
            }
        } else {
            initJsonLd.article(data);
            setPrerenderReady(true);
        }
    }, [isMetaReady, storyData, authorData, gameData]);

    function handleDOMChange(newState): void {
        if (!isStory || isPrerenderReady || !meta || !meta.title || !meta.description) {
            return;
        }

        const withImage = !!newState.metaTags.find((tag) => tag.property === 'og:image' && tag.content.length > 0);

        setMetaReady(withImage);
    }

    function withBrand(content: string): string {
        const brand = theBrand;

        if (!content) {
            return;
        }

        if (noBrand) {
            return content;
        }

        return `${content} | ${brand}`;
    }

    function getImageData() {
        if (forcedImage) {
            return {
                image: forcedImage,
                imageAlt: title,
                imageWidth: imageWidth || '893',
                imageHeight: imageHeight || '460',
                cardType: 'summary_large_image',
            };
        }

        if (image) {
            return {
                image: photon(image, imageProps.width, imageProps.height, imageProps.factor),
                imageAlt: title,
                imageWidth: '893',
                imageHeight: '460',
                cardType: 'summary_large_image',
            };
        }

        return {
            image: theDefaultLogo,
            imageAlt: withBrand(theBrand),
            imageWidth: '400',
            imageHeight: '400',
            cardType: 'summary',
        };
    }

    function getIcons() {
        const appleIcons = Object.entries(icons).map(([size, href]) => (
            <link
                key={`icon-size-${size}`}
                rel="apple-touch-icon"
                sizes={`${size}x${size}`}
                href={href}
            />
        ));

        return [
            <link
                key="icon-192"
                rel="shortcut icon"
                type="image/png"
                sizes="192x192"
                href={icons[192]}
            />,
            <link
                key="icon-128"
                rel="shortcut icon"
                type="image/png"
                sizes="128x128"
                href={icons[128]}
            />,
            <link key="favicon" rel="icon" sizes="any" type="image/svg+xml" href={theSvgIcon} />,
            <link
                key="favicon-short"
                rel="shortcut icon"
                sizes="any"
                type="image/svg+xml"
                href={theSvgIcon}
            />,
            ...appleIcons,
        ];
    }

    function renderInitialMeta(): React.ReactNode {
        return [
            ...getIcons(),
            isStory && !window.location.href.includes(slug)
                ? <meta key="301" httpEquiv="Status" content={`301|${meta.canonical}`} />
                : '',
            httpEquiv ? <meta key="meta-equiv" httpEquiv="Status" content={httpEquiv} data-renderilla-prepend /> : '',
            noIndex ? <meta key="meta-noindex" name="robots" content="noindex" data-renderilla-prepend /> : '',
            <title key="meta-title-common">{meta.titleBranded}</title>,
            <meta key="meta-title" name="title" content={meta.titleBranded} />,
            <meta key="description" name="description" content={meta.description} />,
            <meta key="url" name="url" content={meta.absoluteUrl} />,
            <link key="canonical" rel="canonical" href={meta.canonical} />,
            <link key="image" rel="image_src" href={meta.image} />,
            <link
                key="search-plugin"
                rel="search"
                type="application/opensearchdescription+xml"
                title={theBrand}
                href={searchPlugin}

            />,
            <link key="wa-manifest" rel="manifest" href={theManifest} />,
        ];
    }

    function renderOgMeta(): React.ReactNode {
        return [
            <meta key="title" property="og:title" content={meta.title} data-renderilla-prepend />,
            <meta key="description" property="og:description" content={meta.description} data-renderilla-prepend />,
            <meta key="url" property="og:url" content={meta.absoluteUrl} data-renderilla-prepend />,
            <meta key="type" property="og:type" content="website" data-renderilla-prepend />,
            <meta key="site_name" property="og:site_name" content={theBrand} data-renderilla-prepend />,
            <meta key="image" property="og:image" content={meta.image} data-renderilla-prepend />,
            <meta key="image:alt" property="og:image:alt" content={meta.imageAlt} data-renderilla-prepend />,
            <meta key="image:width" property="og:image:width" content={meta.imageWidth} data-renderilla-prepend />,
            <meta key="image:height" property="og:image:height" content={meta.imageHeight} data-renderilla-prepend />,
        ];
    }

    function renderTwitterMeta(): React.ReactNode {
        return [
            <meta key="title" name="twitter:title" content={meta.titleBranded} />,
            <meta key="description" name="twitter:description" content={meta.description} />,
            <meta key="image" name="twitter:image" content={meta.image} />,
            <meta key="card" name="twitter:card" content={meta.cardType} />,
            <meta key="site" name="twitter:site" content="media.vkplay.ru" />,
        ];
    }

    function renderMyWidgetMeta(): React.ReactNode { // relap
        return [
            <meta key="title" property="mywidget:title" content={meta.title} />,
            <meta key="image" property="mywidget:image" content={meta.image} />,
            <meta key="section" property="mywidget:article_section" content={singular.ru[meta.alias]} />,
            <meta key="section2" name="relap:article_section" content={singular.ru[meta.alias]} />,
            <meta key="section3" name="article:section" content={singular.ru[meta.alias]} />,
            <meta key="section4" property="article:section" content={singular.ru[meta.alias]} />,
            <meta key="published" property="article:published_time" content={meta.published} />,
        ];
    }

    if (!meta) {
        return null;
    }

    return (
        <Helmet onChangeClientState={handleDOMChange}>
            {renderInitialMeta()}
            {renderOgMeta()}
            {renderTwitterMeta()}
            {isStory && renderMyWidgetMeta()}
        </Helmet>
    );
};

Head.defaultProps = {
    // eslint-disable-next-line react/default-props-match-prop-types
    title: 'Медиа',
    titlePostfix: '',
};

export default Head;
