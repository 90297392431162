import { useEffect, useState } from 'react';

import { useRouteMatch, useLocation } from 'react-router-dom';

import { gameSpecialPages } from 'aliases';

const prefix = window.VKPAppConfig.pathPrefix;

export default function useCurrentRoutePage(): string {
    const [page, setPage] = useState('');
    const location = useLocation();

    const gameMatch = useRouteMatch(`${prefix}/games/:alias?/:slug?`);
    const articleMatch = useRouteMatch(`${prefix}/articles/:alias/:slug/`);
    const newsMatch = useRouteMatch(`${prefix}/news/:alias/:slug/`);
    const videoMatch = useRouteMatch(`${prefix}/video/:slug/`);
    const gofMatch = useRouteMatch('/gof/');
    const gofArticleMatch = useRouteMatch('/gof/:slug/');
    const teamMatch = useRouteMatch(`${prefix}/team/`);
    const teamNewsMatch = useRouteMatch(`${prefix}/team-news/`);
    const teamArticlesMatch = useRouteMatch(`${prefix}/team-articles/`);
    const teamSecretsMatch = useRouteMatch(`${prefix}/team-secrets/`);
    const specialMatch = useRouteMatch('/special/');
    const homeMatch = useRouteMatch({ path: `${prefix}/`, strict: true });
    const ugcMatch = useRouteMatch('/ugc/');
    const ugcArticleMatch = useRouteMatch('/ugc/:alias/:slug');

    useEffect(
        () => {
            if (specialMatch) {
                setPage('special');

                return;
            }

            if (homeMatch && homeMatch.isExact) {
                setPage('home');

                return;
            }

            if (gofArticleMatch) {
                setPage('gof-article');

                return;
            }

            if (gofMatch && gofMatch.isExact) {
                setPage('gof');

                return;
            }

            if (teamMatch || teamNewsMatch || teamArticlesMatch || teamSecretsMatch) {
                setPage('team');

                return;
            }

            if (gameMatch) {
                if (!gameMatch.params.alias || gameSpecialPages.includes(gameMatch.params.alias)) {
                    setPage('games');

                    return;
                }

                setPage('game');

                return;
            }

            if (newsMatch) {
                setPage('news');

                return;
            }

            if (articleMatch) {
                setPage('article');

                return;
            }

            if (videoMatch) {
                setPage('video');

                return;
            }

            if (ugcArticleMatch) {
                setPage('ugc-article');

                return;
            }

            if (ugcMatch) {
                setPage('ugc');

                return;
            }

            setPage('');
        },
        [location],
    );

    return page;
}
