import React, { useRef } from 'react';

import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { useAnalyticsMethods } from '@vkplay/analytics';
import { Switch, Route } from 'react-router-dom';

import { useUpdateCounter } from 'contexts/updateCounter';

import NavigationVKP from '@VKP/Navigation';
import { gameSpecialPages } from 'aliases';
import Navigational from 'layouts/Navigational';
import Prerendered from 'layouts/Prerendered';
import { IS_PRODUCTION } from 'src/constants';

import Game from 'pages/Game';
import Games from 'pages/Games';
import QuizVKP from 'src/pages/QuizVKP';

const Home = React.lazy(() => import('@VKP/pages/Home'));
const Strip = React.lazy(() => import('templates/Strip'));
const Story = React.lazy(() => import('pages/Story'));
const UGC = React.lazy(() => import('pages/UGC'));
const Team = React.lazy(() => import('pages/Team'));
const Files = React.lazy(() => import('pages/articles/file'));
const Swagger = React.lazy(() => import('pages/Swagger'));
const NotFound = React.lazy(() => import('templates/NotFound'));
const VideoPage = React.lazy(() => import('pages/VideoPage'));
const ErrorPage = React.lazy(() => import('pages/ErrorPage'));

let loadCounter = 0;

const Router: React.FC = () => {
    const prevPageRef = useRef<string>();
    const { pushPageView } = useAnalyticsMethods();

    const { incrementUpdateCounter } = useUpdateCounter();

    return (
        <>
            <NavigationVKP />
            <Navigational>
                <Prerendered>
                    <MantineProvider
                        theme={{
                            colorScheme: 'dark',
                        }}
                    >
                        <Notifications position="top-right" zIndex={99999} />
                        <Switch>
                            <Route
                                exact
                                path="/"
                                component={Home}
                            />
                            <Route
                                exact
                                path="/team-news/"
                                render={(props) => <Team {...props} type="vkpNews" />}
                            />
                            <Route
                                exact
                                path="/team-articles/"
                                render={(props) => <Team {...props} type="vkpArticles" />}
                            />
                            <Route
                                exact
                                path="/news/"
                                render={(props) => <Strip {...props} type="vkp-news" />}
                            />
                            <Route
                                exact
                                path="/articles/"
                                render={(props) => <Strip {...props} type="vkp-feat" majorType="articles" />}
                            />
                            <Route
                                exact
                                path="/file"
                                component={Files}
                            />
                            <Route
                                exact
                                path="/feat/"
                                render={(props) => <Strip {...props} type="vkp-feat" majorType="articles" />}
                            />
                            <Route
                                exact
                                path="/secret/"
                                render={(props) => <Strip {...props} type="vkp-secret" majorType="articles" />}
                            />
                            <Route
                                exact
                                path="/feat/viktorina-vk-play/"
                                component={QuizVKP}
                            />
                            <Route
                                exact
                                path="/articles/:alias/"
                                render={(props) => <Strip {...props} majorType="articles" />}
                            />
                            <Route
                                exact
                                path="/video/:slug/"
                                component={VideoPage}
                            />
                            <Route
                                exact
                                path="/industry/"
                                render={(props) => <Strip {...props} type="industry" getParam="&tag=industry" />}
                            />
                            <Route
                                exact
                                path="/industry/:alias/"
                                render={(props) => <Strip {...props} majorType="industry" getParam="&tag=industry" />}
                            />
                            <Route
                                exact
                                path="/games/"
                                render={(props) => <Games {...props} majorType="games" />}
                            />
                            <Route
                                path="/games/:alias/:slug?"
                                render={(props) => {
                                    const { match: { params: { alias, slug } } } = props;

                                    if (gameSpecialPages.includes(alias)) {
                                        return <Games {...props} alias={alias} slug={slug} majorType="games" />;
                                    }

                                    return <Game {...props} slug={alias} tab={slug} />;
                                }}
                            />
                            <Route
                                exact
                                path="/ugc/my/"
                                component={UGC}
                            />
                            <Route
                                path="/:type/:alias/:slug/"
                                component={Story}
                            />
                            <Route
                                path="/:alias/:slug/"
                                component={Story}
                            />
                            <Route
                                path="/error/"
                                component={ErrorPage}
                            />
                            {!IS_PRODUCTION && (
                                <Route
                                    exact
                                    path="/api/"
                                    component={Swagger}
                                />
                            )}
                            <Route path="*">
                                <NotFound />
                            </Route>
                        </Switch>
                    </MantineProvider>
                </Prerendered>
            </Navigational>
            <Route
                path="/"
                render={({ location }) => {
                    if (loadCounter > 0) {
                        const currentUrl = location.pathname;

                        if (prevPageRef.current !== currentUrl || location.search.includes('q=')) {
                            pushPageView({
                                url: window.location.href,
                                referrer: prevPageRef.current,
                            });
                        }

                        // отдельное условие, чтобы не было рекурсивного вызова
                        if (prevPageRef.current !== currentUrl) {
                            incrementUpdateCounter();
                        }
                    }

                    loadCounter += 1;
                    prevPageRef.current = location.pathname;
                }}
            />
        </>
    );
};

export default React.memo(Router);
