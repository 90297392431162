const { imgHost } = window.VKPAppConfig;

export default function photon(
    link: string,
    initWidth: number,
    initHeight: number,
    factor: number,
    initFormat: string,
    qualityNum: number,
    noGif?: boolean,
): string {
    let modifiedLink = link;

    if (typeof modifiedLink !== 'string') {
        return '';
    }

    if (modifiedLink.includes('.gif') && !noGif) {
        return modifiedLink;
    }

    const width = Math.round(initWidth * (factor === 1 ? 1 : 1.6));
    const height = Math.round(initHeight * (factor === 1 ? 1 : 1.6));

    const quality = typeof qualityNum === 'number' ? `?quality=${qualityNum}` : '?quality=85';
    let format = '';

    if (initFormat) {
        format = `&format=${initFormat}`;
    } else if (modifiedLink.includes('.png')) {
        format = '&format=jpeg';
    }

    if (modifiedLink.includes('store.my.games')) {
        modifiedLink = modifiedLink.replace('store.my.games', 'games.mail.ru');
    }

    if (modifiedLink.includes('/pre_1000x0_resize/')) {
        modifiedLink = modifiedLink.replace('/pre_1000x0_resize/', '/');
    }

    if (modifiedLink.includes('vkplay.ru/')) {
        modifiedLink = modifiedLink.replace(
            'vkplay.ru/',
            `vkplay.ru/pre_${width}x${height}_resize/`,
        ) + quality + format;

        return modifiedLink;
    }

    if (modifiedLink.includes('.mail.ru/')) {
        modifiedLink = modifiedLink.replace('.mail.ru/', `.mail.ru/pre_${width}x${height}_resize/`) + quality + format;

        modifiedLink = modifiedLink.replace('games.mail.ru', 'vkplay.ru');

        return modifiedLink;
    }

    if (modifiedLink.includes('hotbox')) {
        modifiedLink = `https://${imgHost}/pre_${width}x${height}_resize${modifiedLink}${quality}${format}`;
    }

    return modifiedLink;
}
